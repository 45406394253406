import React from "react";
import { registerables } from "chart.js/auto";
// import { PieController, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { ReactChart } from "chartjs-react";

export default function HomeCategoryChart({ info }) {
  ReactChart.register(...registerables);

  const COLORS = [
    "#F66D44",
    "#FEAE65",
    "#E6F69D",
    "#AADEA7",
    "#64C2A6",
    "#2D87BB",
    "#A9A9A9",
    "#DDDDDD",
    "#C608D1",
    "#FFA9FD",
    "#2900A5",
    "#2900A5",
  ];

  let labels = [];
  let values = [];
  let colors = [];

  if (info) {
    const nonZero = info.filter((item) => item.value !== "0");

    labels = nonZero.map((item) => item.name);
    values = nonZero.map((item) =>
      item.value.replaceAll(".", "").replaceAll(",", ".")
    );

    for (let i = 0; i < values.length; i++) colors.push(COLORS[i]);
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "$ ",
        data: values,
        backgroundColor: colors,
        hoverBorderColor: "#ffffff",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "left",
        labels: {
          font: {
            size: 10,
          },
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
        },
      },
      title: {
        display: true,
        text: "EXPENSES PER CATEGORY",
        position: "bottom",
        padding: {
          top: 10,
        },
      },
    },
    responsive: false,
    maintainAspectRadio: false,
  };

  const allZero = info.every((item) => item.value === "0");

  if (allZero) {
    return (
      <div className="flex py-4 bg-white rounded-lg shadow">
        <span className="flex-1 text-center text-sm text-slate-600">No values found to render a chart!</span>
      </div>
    );
  } else {
    return <ReactChart type="pie" data={chartData} options={options} />;
  }
}
