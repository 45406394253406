import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import CategoryItem from "./CategoryItem";
// import { sortList } from './utils';

export default function Categories() {
  const { userSession } = useContext(UserContext);

  const [categories, setCategories] = useState(null);
  const [categoryForm, setCategoryForm] = useState(false);
  const [newCategory, setNewCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      getCategories(userSession.user.id);
      setLoading(false);
    })();
  }, [userSession]);

  const toggleCategoryForm = () => setCategoryForm(!categoryForm);

  const sortList = (list) =>
    list.sort(
      (a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name)
    );

  const getCategories = async (id) => {
    try {
      const { data: categories, error } = await supabase
        .from("categories")
        .select("id,type,name")
        .eq("user_id", id);
      if (error) throw error;
      setCategories(sortList(categories));
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const addCategories = async (newCategory) => {
    try {
      const { data, error } = await supabase
        .from("categories")
        .insert([{ ...newCategory, user_id: userSession.user.id }]);
      if (error) throw error;
    } catch (error) {
      console.log("error@categories:", error);
    }
  };

  const removeCategory = async (id, name) => {
    const message = window.confirm(
      `Are you sure you want to delete ${name.toUpperCase()}?`
    );
    if (message) {
      try {
        const { data, error } = await supabase
          .from("categories")
          .delete()
          .eq("id", id);
        if (error) throw error;
        setCategories(categories.filter((item) => item.id !== id));
      } catch (error) {
        // TODO: make this a modal
        alert("Cannot delete account that is being used in TRANSACTIONS");
        console.log("error@categories:", error);
      }
    } else {
      return;
    }
  };

  const updateCategory = (event, row) => {
    const index = categories.findIndex((item) => item.id === row.id);
    const temp = [...categories];
    temp[index] = { ...temp[index], [event.target.name]: event.target.value };
    setCategories(temp);
  };

  const handleChange = (event) => {
    setNewCategory({ ...newCategory, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addCategories(newCategory);
    const temp = [...categories, newCategory];
    setCategories(sortList(temp, "descending"));
    toggleCategoryForm();
  };

  const fullCategory = () => {
    if (!newCategory) return;
    return newCategory.type && newCategory.name ? true : false;
  };

  const handleKeys = (event) => {
    if (event.key === "Enter") event.preventDefault();
  };

  return (
    <>
      <div>
        {categoryForm ? (
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-5 gap-2 text-sm items-center text-slate-600 mb-1 max-[680px]:grid-cols-1 max-[680px]:grid-rows-5"
          >
            <select
              className=""
              name="type"
              onChange={handleChange}
              defaultValue={""}
            >
              <option value="" disabled>
                select category type
              </option>
              <option value="income">Income</option>
              <option value="expense">Expense</option>
            </select>
            <input
              required
              className=""
              autoComplete="off"
              placeholder="enter name of new category"
              type="text"
              name="name"
              onChange={handleChange}
              onKeyDown={handleKeys}
            />
            <div />
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setCategoryForm(false)}
              className="text-slate-400 place-self-center text-xl hover:text-slate-700 cursor-pointer"
            />
            <FontAwesomeIcon
              icon={faFloppyDisk}
              onClick={handleSubmit}
              type="submit"
              disabled={fullCategory() ? false : true}
              className={
                fullCategory()
                  ? "place-self-center text-xl text-slate-600 cursor-pointer hover:text-slate-400"
                  : "place-self-center text-xl text-slate-300"
              }
            />
          </form>
        ) : (
          <div className="items-center">
            <button
              onClick={() => setCategoryForm(true)}
              className="text-center text-sm font-bold text-slate-500 hover:text-slate-800"
            >
              ADD NEW CATEGORY
            </button>
          </div>
        )}
      </div>
      <br />
      <div className="items-center grid grid-cols-10 p-2 font-bold text-white text-lg bg-gradient-to-t from-slate-600 to-slate-400 min-h-[56px]">
        <div className="col-span-2">TYPE</div>
        <div className="col-span-4">NAME</div>
        <div className="col-span-2 flex-col align-middle text-sm"></div>
        <div className="text-center text-xs">EDIT</div>
        <div className="text-center text-xs">REMOVE</div>
      </div>
      {loading ? (
        <div className="flex min-w-full min-h-full items-center place-content-center mt-8">
          <FontAwesomeIcon
            icon={faSpinner}
            className="flex-1 text-4xl text-slate-600 animate-spin"
          />
        </div>
      ) : (
        <div>
        { categories 
          ? categories.map((item, i) => {
          return (
            <CategoryItem
              key={i}
              categoryRow={item}
              removeCategory={() => removeCategory(item.id, item.name)}
              categories={categories}
              updateCategory={updateCategory}
            />
          );
        })
        : null 
        }
        </div>
      )}
    </>
  );
}
