import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faSackDollar,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import * as utils from "./utils";
import Transfers from "./Transfers";
import HomeCategoryChart from "./HomeCategoryChart";
import HomeBalanceChart from "./HomeBalanceChart";
// import Modal from "./Modal";
// import Page from "./Page";

// TODO: change the way credit cards are handled: separate from accounts.
// TODO: enable sort and search in transactions
// TODO: start reports page

export default function Home() {
  const { userSession } = useContext(UserContext);

  // const [showModal,setShowModal] = useState(false)

  const [transactions, setTransactions] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState(null);
  const [categories, setCategories] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [transfers, setTransfers] = useState(null);
  const [startDay, setStartDay] = useState(utils.getCurrentTimePeriod()[0]);
  const [endDay, setEndDay] = useState(utils.getCurrentTimePeriod()[1]);
  const [loading, setLoading] = useState(true);
  const [categoryChart, setCategoryChart] = useState(null);
  const [balanceChart, setBalanceChart] = useState(null);

  useEffect(() => {
    (async () => {
      await getTransactions(userSession.user.id);
      await getAccounts(userSession.user.id);
      await getCategories(userSession.user.id);
      await getTransfers(userSession.user.id);
      setLoading(false);
    })();
  }, [userSession]);

  useEffect(() => {
    if (categories) {
      setCategoryChart(
        categories
          ?.filter((item) => item.type === "expense")
          .map((item) => {
            return {
              name: item.name.toUpperCase(),
              value: utils.currency(
                utils.reduceCategory(filteredTransactions, item.type, item.name)
              ),
            };
          })
      );
    }
  }, [categories, filteredTransactions]);

  useEffect(() => {
    if (accounts && transactions) {
      const dates = [...new Set(transactions.map((item) => item.duedate))];
      if (dates && dates.length > 1) {
        setBalanceChart(
          utils.makeBalanceArray(accounts, transactions).reverse()
        );
      }
    }
  }, [accounts, transactions]);

  useEffect(() => {
    if (startDay > endDay)
      setEndDay(utils.getFirstOrLastDayOfMonth(startDay, "last"));
  }, [startDay, endDay]);

  useEffect(() => {
    setFilteredTransactions(utils.filterByDate(transactions, startDay, endDay));
  }, [startDay, endDay, transactions]);

  const handleStartDate = (event) =>
    setStartDay(() => {
      return event.target.value ?? utils.getCurrentTimePeriod()[0];
    });

  const handleEndDate = (event) => {
    setEndDay(() => {
      if (event.target.value < startDay)
        setStartDay(
          utils.getFirstOrLastDayOfMonth(event.target.value, "first")
        );
      return event.target.value ?? utils.getCurrentTimePeriod()[1];
    });
  };

  const getTransactions = async (id) => {
    try {
      const { data: transactions, error } = await supabase
        .from("transactions")
        .select(
          `
          id,
          created_at,
          account,
          accounts(id,initialBalance,name,type),
          category,
          categories(id,type,name),
          description,
          duedate,
          amount
          `
        )
        .eq("user_id", id)
        .order("duedate", { ascending: false });
      if (error) throw error;

      const transactionList = transactions.map((item) => {
        return {
          account: item.accounts.name,
          category: item.categories.name,
          amount: item.amount,
          type: item.categories.type,
          duedate: item.duedate.slice(0, 10),
        };
      });
      setTransactions(transactionList);
      setFilteredTransactions(transactionList);
      // console.log("transactions =>", transactions);
      // console.log("transactionList =>", transactionList);
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const getAccounts = async (id) => {
    try {
      const { data: accounts, error } = await supabase
        .from("accounts")
        .select("id,initialBalance,name,type")
        .eq("user_id", id);
      if (error) throw error;
      setAccounts(utils.sortList(accounts, "descending"));
      // console.log("accounts =>", accounts);
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const getCategories = async (id) => {
    try {
      const { data: categories, error } = await supabase
        .from("categories")
        .select("id,type,name")
        .eq("user_id", id);
      if (error) throw error;
      setCategories(utils.sortList(categories, "ascending"));
      // console.log("categories =>", categories);
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const getTransfers = async (id) => {
    try {
      const { data: transfers, error } = await supabase
        .from("transfers")
        .select("*")
        .eq("user_id", id);
      if (error) throw error;
      setTransfers(transfers);
      // console.log("transfers =>", transfers);
    } catch (error) {
      console.log("error@transfers:", error);
    }
  };

  const typeIcon = (type) => {
    let icon;
    if (type === "bank") icon = faBuildingColumns;
    if (type === "creditcard") icon = faCreditCard;
    if (type === "investments") icon = faSackDollar;
    return icon;
  };

  const checkTimePeriod = (start, end) =>
    start !== utils.getCurrentTimePeriod()[0] ||
      end !== utils.getCurrentTimePeriod()[1]
      ? true
      : false;

  const resetTimePeriod = () => {
    setStartDay(utils.getCurrentTimePeriod()[0]);
    setEndDay(utils.getCurrentTimePeriod()[1]);
  };

  return (
    <>
      {loading ? (
        <div className="flex min-w-full min-h-screen items-center place-content-center justify-center">
          <FontAwesomeIcon
            icon={faSpinner}
            className="text-8xl text-slate-600 animate-spin"
          />
        </div>
      ) : (
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-4 mt-2">
          <div className="col-span-4 border-b-2 border-slate-200 flex items-center">
            <span className="text-left flex-1 text-slate-700">
              Your balance today
            </span>
            <span className="text-right flex-1 text-xl mr-4">
              {accounts
                ? utils.money(
                  utils.totalInitialBalance(accounts) +
                  utils.totalTransactionsUntilToday(
                    transactions,
                    utils.today
                  )
                )
                : 0}
            </span>
          </div>
          <div className="col-span-4 flex flex-1 py-2 bg-white shadow-md px-4 rounded-lg">
            <div className="flex flex-[0.5] items-center">
              <span className="flex-1 text-center text-slate-600 flex-wrap lg:text-right">
                TIME PERIOD
              </span>
              <button
                className="flex-1 text-xs text-slate-400 font-bold disabled:text-transparent hover:text-slate-600"
                onClick={resetTimePeriod}
                disabled={!checkTimePeriod(startDay, endDay)}
              >
                RESET
              </button>
            </div>
            <div className="flex flex-1 gap-4">
              <div className="flex flex-1 items-center gap-2">
                <span className="flex-0 text-center text-slate-600 text-xs font-medium">
                  from
                </span>
                <input
                  type="date"
                  className="text-center text-sm flex-1 py-1 outline-slate-200"
                  value={startDay}
                  onChange={handleStartDate}
                />
              </div>
              <div className="flex flex-1 items-center gap-2">
                <span className="flex-0 text-center text-slate-600 text-xs font-medium">
                  to
                </span>
                <input
                  type="date"
                  className="text-center text-sm flex-1 py-1 outline-slate-200"
                  value={endDay}
                  onChange={handleEndDate}
                />
              </div>
            </div>
          </div>
          <div className="flex max-lg:flex-col max-lg:gap-4 lg:col-span-4">
            <div className="flex flex-1 py-2 shadow-md rounded-lg bg-lime-400/20 max-lg:items-center lg:bg-white lg:flex-col lg:divide-y-4 lg:divide-lime-400/50 lg:py-4 lg:m-4">
              <span className="flex-1 text-center text-sm lg:text-left lg:indent-8 tracking-widest lg:py-1">
                INCOME
              </span>
              <span className="flex-1 text-center lg:text-xl lg:pt-2">
                {filteredTransactions
                  ? utils.currency(
                    utils.totalTransactions(filteredTransactions, "income")
                  )
                  : 0}
              </span>
            </div>
            <div className="flex max-lg:items-center flex-1 py-2 shadow-md rounded-lg bg-rose-400/20 lg:bg-white lg:flex-col lg:divide-y-4 lg:divide-rose-400/50 lg:py-4 lg:m-4">
              <span className="flex-1 text-center text-sm lg:text-left lg:indent-8 tracking-widest lg:py-1">
                EXPENSE
              </span>
              <span className="flex-1 text-center lg:text-xl lg:pt-2">
                {filteredTransactions
                  ? utils.currency(
                    utils.totalTransactions(filteredTransactions, "expense")
                  )
                  : 0}
              </span>
            </div>
            <div className="flex max-lg:items-center flex-1 py-2 shadow-md rounded-lg bg-blue-400/20 lg:bg-white lg:flex-col lg:divide-y-4 lg:divide-blue-400/50 lg:py-4 lg:m-4">
              <span className="flex-1 text-center text-sm lg:text-left lg:indent-8 tracking-widest lg:py-1">
                NET RESULT
              </span>
              <span className="flex-1 text-center lg:text-xl lg:pt-2">
                {filteredTransactions
                  ? utils.currency(
                    utils.totalTransactions(filteredTransactions, "income") -
                    utils.totalTransactions(filteredTransactions, "expense")
                  )
                  : 0}
              </span>
            </div>
            <div className="flex max-lg:items-center flex-1 py-2 shadow-md rounded-lg bg-gray-400/20 lg:bg-white lg:flex-col lg:divide-y-4 lg:divide-gray-400/50 lg:py-4 lg:m-4">
              <span className="flex-1 text-center text-sm lg:text-left lg:indent-8 tracking-widest lg:py-1">
                BALANCE
              </span>
              <span className="flex-1 text-center lg:text-xl lg:pt-2">
                {accounts
                  ? utils.currency(
                    utils.totalInitialBalance(accounts) +
                    utils.totalTransactionsUntilToday(transactions, endDay)
                  )
                  : 0}
              </span>
            </div>
          </div>
          <div className="hidden lg:col-span-4 lg:flex">
            {balanceChart ? (
              <div className="max-md:hidden relative m-auto">
                <HomeBalanceChart info={balanceChart} />
              </div>
            ) : null}
          </div>
          <div className="col-span-2 flex">
            {categories.length && categoryChart ? (
              <div className="flex-1">
                <HomeCategoryChart info={categoryChart} />
              </div>
            ) : (
              <span className="flex-1 text-md text-slate-500 place-self-center text-center">
                No values were found to show a chart.
              </span>
            )}
          </div>
          <div className="flex flex-col col-span-2 col-start-3 h-fit shadow-md bg-white rounded-lg items-stretch m-4">
            <span className="flex-1 text-slate-600 text-center text-sm py-2 bg-slate-400/20 rounded-t-lg">
              ACCOUNT BALANCE
            </span>
            <div className="flex-1 divide-y-2 divide-slate-100 text-sm border-b-2 border-slate-200 items-center">
              {transactions && accounts.length ? (
                accounts.map((item) => {
                  const balanceUntilDate = utils.getBalanceUntilDate(
                    transactions,
                    item.name,
                    endDay
                  );
                  // console.log(balanceUntilDate);
                  const balance = utils.currency(
                    item.initialBalance +
                    utils.getBalanceUntilDate(
                      transactions,
                      item.name,
                      endDay
                    ) +
                    utils.getTransfersUntilDate(transfers, item.id, endDay)
                  );
                  return (
                    <div
                      key={item.id}
                      className="grid grid-cols-2 items-center py-1 odd:bg-white even:bg-slate-100"
                    >
                      <div className="flex items-center">
                        <FontAwesomeIcon
                          className="flex-0 text-slate-500 ml-2"
                          icon={typeIcon(item.type)}
                        />
                        <span className="flex-1 text-left ml-4 py-1 max-[480px]:py-0">
                          {item.name.toUpperCase()}
                        </span>
                      </div>
                      <span className="flex-1 text-right py-1 mr-4 max-[480px]:py-0">
                        {balance}
                      </span>
                    </div>
                  );
                })
              ) : (
                <span className="flex-1 text-md text-slate-500 text-center">
                  No accounts yet registered.
                </span>
              )}
            </div>
          </div>
          {/*<div className="flex flex-col col-span-3 mx-8">
        <div className="flex flex-0 col-span-2 text-slate-500 text-xs bg-slate-50 border-2 border-slate-200 py-2 rounded-t-lg items-baseline">
          <span className="flex-1 text-left ml-8">CATEGORIES</span>
          <span className="flex-1 text-right mr-4">TOTAL</span>
        </div>
        <div className="col-span-2 mb-8 divide-y-2 divide-slate-200 text-sm border-b-2 border-slate-200">
          {transactions && categories
            ? categories.map((item) => {
                return (
                  <div key={item.id} className="grid grid-cols-2 gap-4">
                    <div className="flex items-baseline">
                      <FontAwesomeIcon
                        className={`flex-0 ${
                          item.type === "income"
                            ? "ml-2 text-green-600"
                            : "ml-2 text-rose-600"
                        }`}
                        icon={item.type === "income" ? faArrowUp : faArrowDown}
                      />
                      <span className="flex-1 text-left ml-4 py-1">
                        {item.name.toUpperCase()}
                      </span>
                    </div>
                    <span
                      className={`flex-1 text-right py-1 ${
                        item.type === "income"
                          ? "bg-lime-400/10"
                          : "bg-rose-400/10"
                      }`}
                    >
                      {utils.currency(
                        utils.reduceCategory(
                          filteredTransactions,
                          item.type,
                          item.name
                        )
                      )}
                    </span>
                  </div>
                );
              })
            : null}
        </div>
      </div>*/}
        </div>
      )}
    </>
  );
}
