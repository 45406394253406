import React, { useState, useContext } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faArrowRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import * as utils from "./utils";
import { TransactionContext } from "../contexts/TransactionContext";

export default function Transfers({ toggleTransferModal }) {
  const { userSession } = useContext(UserContext);

  const { accounts, transactions, transfers } = useContext(TransactionContext);

  const [newTransfer, setNewTransfer] = useState();

  const addTransfer = async (newTransfer) => {
    try {
      const { data, error } = await supabase
        .from("transfers")
        .insert({ ...newTransfer, user_id: userSession.user.id });
      if (error) throw error;
    } catch (error) {
      console.log("error@transfers:", error);
    }
  };

  const renderFromAccounts = () => {
    if (!accounts) return;
    const filtered = accounts.filter((item) => item.type !== "creditcard");
    return accounts
      ? filtered.map((item) => (
        <option key={item.id} value={item.id}>
          [ {item.type.toUpperCase()} ] {item.name.toUpperCase()}
        </option>
      ))
      : null;
  };

  const renderToAccounts = () => {
    if (!newTransfer.from) return;
    const filtered = accounts.filter((item) => item.type !== "creditcard");
    return filtered
      .filter((item) => item.id !== Number(newTransfer.from))
      .map((item) => (
        <option key={item.id} value={item.id}>
          [ {item.type.toUpperCase()} ] {item.name.toUpperCase()}
        </option>
      ));
  };

  const handleChange = (event) => {
    setNewTransfer({ ...newTransfer, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!newTransfer.due_date) {
      setNewTransfer({ ...newTransfer, due_date: utils.today });
    }
    addTransfer(newTransfer);
    setNewTransfer({});
  };

  const handleReset = (event) => {
    event.preventDefault();
    setNewTransfer({});
  };

  const fullTransfer = () => {
    if (!newTransfer) return;
    return newTransfer.from && newTransfer.to && newTransfer.amount
      ? true
      : false;
  };

  const anyInputTransfer = () => {
    if (!newTransfer) return;
    return newTransfer.from || newTransfer.to || newTransfer.amount
      ? true
      : false;
  };

  const showAccountBalance = () => {
    if (!newTransfer) return;
    const acc = accounts.find((item) => item.id === Number(newTransfer.from));
    const today = utils.today;
    const filtered = transactions.filter(
      (item) => item.duedate <= today && item.account === acc.id
    );
    const income = filtered
      .filter((item) => item.categoryType === "income")
      .reduce((a, b) => a + b.amount, 0);
    const expense = filtered
      .filter((item) => item.categoryType === "expense")
      .reduce((a, b) => a + b.amount, 0);
    const accBalance = income - expense;
    const accTransfers = utils.getTransfersUntilDate(transfers, acc.id, today);
    const res = acc.initialBalance + accBalance + accTransfers;
    return acc ? utils.currency(res) : 0;
  };

  return (
    <div className="fixed z-10 flex flex-col mx-auto top-[12%] left-[265px] bg-slate-50 px-6 py-2 h-[340px] w-[690px]">
      <div className="absolute right-0 top-0 mr-4 mt-2 h-12">
        <FontAwesomeIcon
          icon={faXmark}
          className="flex-1 max-h-12 text-4xl text-slate-500 hover:cursor-pointer hover:text-black"
          onClick={toggleTransferModal}
        />
      </div>
      <div className="flex flex-col h-40 m-8 place-self-stretch max-[600px]:m-0 max-[600px]:min-h-fit">
        <span className="flex-0 col-span-2 text-slate-500 text-sm bg-slate-100 border-2 border-slate-200 py-4 rounded-t-lg text-center">
          TRANSFER BETWEEN ACCOUNTS
        </span>
        <form
          onSubmit={handleSubmit}
          className="flex-1 grid grid-cols-5 mt-2 max-[480px]:flex max-[480px]:flex-col "
        >
          <select
            required
            className={`col-span-2 text-center text-sm text-slate-400 border-2 border-slate-100 bg-slate-100 py-4 cursor-pointer ${newTransfer?.from
                ? "font-bold border-slate-400"
                : "bg-slate-200/20"
              }`}
            name="from"
            onChange={handleChange}
            value={newTransfer?.from ?? ""}
          >
            <option value="" disabled>
              select SENDING account
            </option>
            {accounts ? renderFromAccounts() : null}
          </select>
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-slate-400 text-3xl place-self-center"
          />
          <select
            required
            className={`col-span-2 text-center text-sm text-slate-400 border-2 border-slate-100 bg-slate-100 py-4 cursor-pointer disabled:cursor-default disabled:text-slate-300 ${newTransfer?.to ? "font-bold border-slate-400" : "bg-slate-200/20"
              }`}
            name="to"
            onChange={handleChange}
            disabled={newTransfer?.from ? false : true}
            value={newTransfer?.to ?? ""}
          >
            <option value="" disabled>
              select RECEIVING account
            </option>
            {newTransfer ? renderToAccounts() : null}
          </select>
          <div className="col-span-2 col-start-1 flex py-4 justify-end items-center">
            <span className="flex-1 text-right text-xs text-slate-600">
              current balance:
            </span>
            <span className=" flex-1 text-sm text-slate-600 text-center">
              {newTransfer?.from ? showAccountBalance() : 0}
            </span>
          </div>
          <div className="col-span-2 col-start-1 flex items-center py-4">
            <input
              type="date"
              className="text-center text-sm flex-1 py-1 outline-slate-200"
              value={newTransfer?.due_date ?? utils.today}
              name="due_date"
              onChange={handleChange}
            />
          </div>
          <div className="col-span-2 col-start-4 flex items-center">
            <input
              required
              className="col-span-2 text-center text-sm border-b-2 text-slate-400"
              placeholder="input amount"
              type="number"
              step={0.01}
              name="amount"
              onChange={handleChange}
              value={newTransfer?.amount ?? ""}
            />
          </div>
          <div className="col-span-2 col-start-4 flex items-center mb-4">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleReset}
              type="button"
              disabled={anyInputTransfer() ? false : true}
              className={`flex-1 text-2xl ${anyInputTransfer()
                  ? "text-slate-600 cursor-pointer hover:text-slate-400"
                  : "text-slate-300"
                }`}
            />
            <FontAwesomeIcon
              icon={faFloppyDisk}
              onClick={handleSubmit}
              type="submit"
              disabled={fullTransfer() ? false : true}
              className={`flex-1 text-2xl ${fullTransfer()
                  ? "text-slate-600 cursor-pointer hover:text-slate-400"
                  : "text-slate-300"
                }`}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
