import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { supabase } from "../lib/supabaseClient";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { useSettings } from "../lib/useSettings";
import { toast } from 'react-toastify'
import { today } from './utils.js'
import { TransactionContext } from "../contexts/TransactionContext";

export default function TransactionForm({ setShowDropdownMenu, setShowForm }) {

  const { userSession } = useContext(UserContext)

  const { accounts, categories, transactions, setTransactions } = useContext(TransactionContext)

  const [userGoToPage, setUserGoToPage, userCloseForm, setUserCloseForm] = useSettings();

  const [selectedType, setSelectedType] = useState(null);
  const [selectAccounts, setSelectAccounts] = useState(null);
  const [selectCategories, setSelectCategories] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigation = useNavigate();
  const goTo = () =>
    userGoToPage &&
      newTransaction.description.length &&
      newTransaction.amount &&
      newTransaction.category.length &&
      newTransaction.account.length
      ? navigation("/transactions")
      : null;

  const [newTransaction, setNewTransaction] = useState({
    type: "",
    duedate: today,
    description: "",
    amount: "",
    category: "",
    account: "",
    user_id: userSession.user.id,
  });

  useEffect(() => {
    if (accounts && categories) {
      setLoading(false)
      setSelectAccounts(accounts)
      setSelectCategories(categories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession]);

  useEffect(() => {
    if (newTransaction) setSelectedType(newTransaction.type);
  }, [newTransaction]);

  const addTransaction = async (newTransaction) => {
    try {
      // eslint-disable-next-line
      const { data, error } = await supabase
        .from("transactions")
        .insert([{ ...newTransaction, user_id: userSession.user.id }]);
      // window.location.reload()
      if (error) throw error;
      console.log(newTransaction)
    } catch (error) {
      console.log("error@transactions:", error);
    }
  };

  const handleCloseForm = () => {
    setShowDropdownMenu(false)
    setShowForm(false)
  }

  const findCategoryName = (category) => selectCategories?.filter(item => item.id === Number(category))[0].name
  const findAccountName = (account) => selectAccounts?.filter(item => item.id === Number(account))[0].name

  const handleSubmit = (event) => {
    event.preventDefault();

    const categoryName = findCategoryName(newTransaction.category)
    const accountName = findAccountName(newTransaction.account)
    const newTransactionForDisplay = { ...newTransaction, categoryName, accountName }
    const updatedTransactions = [...transactions, newTransactionForDisplay]
    setTransactions(updatedTransactions)

    toast.success(`${newTransaction.type.toUpperCase()} transaction of $${newTransaction.amount} created!`)

    if (newTransaction.type) delete newTransaction.type
    addTransaction(newTransaction);

    setNewTransaction({
      type: "",
      duedate: "",
      description: "",
      amount: "",
      category: "",
      account: "",
      user_id: userSession.user.id,
    });
    if (userCloseForm) handleCloseForm();
    if (userGoToPage) goTo();
  };

  const completeForm =
    newTransaction &&
      newTransaction.type &&
      newTransaction.duedate &&
      newTransaction.description &&
      newTransaction.amount &&
      newTransaction.category &&
      newTransaction.account
      ? true
      : false;

  const handleKeys = (event) => {
    if (event.key === "Enter") event.preventDefault();
  };

  const renderCategories = () => {
    return selectedType
      ? selectCategories
        .filter((item) => item.type === selectedType)
        .map((item) => {
          return <option key={item.id} value={item.id}>{item.name.toUpperCase()}</option>;
        })
      : null;
  };

  const renderAccounts = () => {
    return selectAccounts
      ? selectAccounts.map((item) => (
        <option key={item.id} value={item.id}>
          [ {item.type.toUpperCase()} ] {item.name.toUpperCase()}
        </option>
      ))
      : null;
  };

  return (
    <div className="flex flex-col">
      {loading ? (
        <div className="flex min-w-full min-h-full items-center place-content-center mt-8">
          <FontAwesomeIcon
            icon={faSpinner}
            className="flex-1 text-4xl text-slate-600 animate-spin"
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-xs">
          <span className="text-center text-sm text-slate-600 font-bold py-2">
            CREATE NEW TRANSACTION
          </span>
          <select
            className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200 text-gray-700"
            name="type"
            onChange={(event) => {
              setNewTransaction({
                ...newTransaction,
                [event.target.name]: event.target.value,
              });
            }}
            value={newTransaction.type ? newTransaction.type : ""}
            autoFocus
          >
            <option value="" disabled>
              select account type
            </option>
            <option value="income">Income</option>
            <option value="expense">Expense</option>
          </select>
          <input
            className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200 text-gray-700"
            type="date"
            name="duedate"
            value={newTransaction.duedate ? newTransaction.duedate : today}
            onChange={(event) => {
              setNewTransaction({
                ...newTransaction,
                [event.target.name]: event.target.value,
              });
            }}
            onKeyDown={handleKeys}
          />
          <input
            className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200 text-gray-700"
            autoComplete="off"
            placeholder="enter description of transaction"
            type="text"
            name="description"
            value={newTransaction.description ? newTransaction.description : ""}
            onChange={(event) => {
              setNewTransaction({
                ...newTransaction,
                [event.target.name]: event.target.value,
              });
            }}
            onKeyDown={handleKeys}
          />
          <input
            className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200 text-gray-700"
            placeholder="enter amount"
            type="number"
            name="amount"
            setp={0.01}
            value={newTransaction.amount ? newTransaction.amount : ""}
            onChange={(event) => {
              setNewTransaction({
                ...newTransaction,
                [event.target.name]: event.target.value,
              });
            }}
            onKeyDown={handleKeys}
          />
          <select
            className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200 text-gray-700"
            name="category"
            value={newTransaction.category ? newTransaction.category : ""}
            onChange={(event) => {
              setNewTransaction({
                ...newTransaction,
                [event.target.name]: event.target.value,
              });
            }}
            onKeyDown={handleKeys}
          >
            <option value="" disabled>
              select category
            </option>
            {renderCategories()}
          </select>
          <select
            className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200 text-gray-700"
            name="account"
            value={newTransaction.account ? newTransaction.account : ""}
            onChange={(event) => {
              setNewTransaction({
                ...newTransaction,
                [event.target.name]: event.target.value,
              });
            }}
            onKeyDown={handleKeys}
          >
            <option value="" disabled>
              select account
            </option>
            {renderAccounts()}
          </select>
          <div className="flex items-center">
            <span className="flex-1 text-center">
              <button>
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={handleCloseForm}
                  className="text-slate-600 text-3xl hover:text-slate-400 cursor-pointer"
                />
              </button>
            </span>
            <span className="flex-1 text-center">
              <button
                onClick={handleSubmit}
                disabled={!completeForm}
              >
                <FontAwesomeIcon
                  icon={faFloppyDisk}
                  type="submit"
                  className={
                    completeForm
                      ? "text-3xl text-slate-600 cursor-pointer hover:text-slate-400"
                      : "text-3xl text-slate-200"
                  }
                />
              </button>
            </span>
          </div>
        </form>
      )}
      <div className="flex-1">
        <div className="mt-32">
          <span className="text-[.6rem] text-slate-700">
            Go to page after saving?
          </span>
        </div>
        <div className="flex items-center gap-1">
          <span className="flex-[2.5] text-right text-[0.5rem] font-bold text-slate-600">
            {userGoToPage ? "ON" : "OFF"}
          </span>
          <button
            className="flex flex-[0.5]"
            onClick={() => setUserGoToPage((state) => !state)}
          >
            <FontAwesomeIcon
              className="flex-1 h-6 text-slate-600 hover:text-slate-400"
              icon={userGoToPage ? faToggleOn : faToggleOff}
            />
          </button>
        </div>
      </div>
      <div className="mt-4">
        <span className="text-[.6rem] text-slate-700">
          Return to menu after saving?
        </span>
        <div className="flex items-center gap-1">
          <span className="flex-[2.5] text-right text-[0.5rem] font-bold text-slate-600">
            {userCloseForm ? "ON" : "OFF"}
          </span>
          <button
            className="flex flex-[0.5]"
            onClick={() => setUserCloseForm((state) => !state)}
          >
            <FontAwesomeIcon
              className="flex-1 h-6 text-slate-600 hover:text-slate-400"
              icon={userCloseForm ? faToggleOn : faToggleOff}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
