import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AccountItem from "./AccountItem";
import { sortList } from "./utils";
import Modal from "./Modal";
import { motion as m } from 'framer-motion'

export default function Accounts() {

  const { userSession } = useContext(UserContext);

  const [accounts, setAccounts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      getAccounts(userSession.user.id);
      setLoading(false);
    })();
  }, [userSession]);

  const getAccounts = async (id) => {
    try {
      const { data: accounts, error } = await supabase
        .from("accounts")
        .select("*")
        .eq("user_id", id);
      if (error) throw error;
      setAccounts(sortList(accounts, "descending"));
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const [showRemoveAccountModal,setShowRemoveAccountModal] = useState(null)

  const showAccountRemovalModal = (id,type,name) => setShowRemoveAccountModal(id,type,name)

  const renderRemoveAccount = () => {
    return (
      <Modal>
        <div className='fixed w-full h-full bg-slate-400/60 z-10'>
          <m.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, ease: "easeInOut"}}
            className='flex flex-col bg-white rounded-lg m-36 items-center'
          >
          <span className='flex-1 text-center py-4'>{`Are you sure you want to delete the ${showRemoveAccountModal.type.toUpperCase()} account ${showRemoveAccountModal.name.toUpperCase()}?`}</span>
          <div className='flex flex-1 items-center py-4 mx-8 w-1/2 gap-8'>
            <div className='flex-1 flex w-full'>
              <button className='flex-1 bg-slate-200 p-4 border border-bg-slate-200 hover:bg-slate-50' onClick={() => setShowRemoveAccountModal(null)}>CANCEL</button>
            </div>
            <div className='flex-1 flex w-full'>
              <button 
                className='flex-1 bg-slate-200 p-4' 
                onClick={() => {
                  handleRemoveAccount(showRemoveAccountModal.id)
                  setShowRemoveAccountModal(null)
                }}>YES</button>
            </div>
          </div>
        </m.div>
      </div>
      </Modal>
    )
  }

  const handleRemoveAccount = async (id) => {
      try {
        const { data: accounts, error } = await supabase
          .from("accounts")
          .delete()
          .match("id", id);
        if (error) throw error;
        setAccounts(accounts.filter((item) => item.id !== id));
      } catch (error) {
        // TODO: make this a modal
        alert("Cannot delete category that is being used in TRANSACTIONS");
        console.log("error@accounts:", error);
      }
  };

//   const updateAccount = (event, row) => {
//     const index = accounts.findIndex((item) => item.id === row.id);
//     const temp = [...accounts];
//     temp[index] = { ...temp[index], [event.target.name]: event.target.value };
//     setAccounts(temp);
//   };

  return (
    <>
      <div className="p-4 bg-white shadow rounded-lg mt-4 max-sm:p-2 max-sm:mt-2">
        { showRemoveAccountModal ? renderRemoveAccount() : null }
        {/*acVcountForm ? (
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-5 gap-2 text-sm items-center text-slate-600 mb-1 max-[680px]:grid-cols-1 max-[680px]:grid-rows-5"
            >
              <select
                className=""
                name="type"
                onChange={handleChange}
                defaultValue={""}
              >
                <option value="" disabled>
                  select account type
                </option>
                <option value="bank">Bank</option>
                <option value="creditcard">Credit Card</option>
                <option value="investments">Investments</option>
              </select>
              <input
                required
                className=""
                autoComplete="off"
                placeholder="enter name of new account"
                type="text"
                name="name"
                onChange={handleChange}
                onKeyDown={handleKeys}
              />
              <input
                required
                className=""
                placeholder="enter initial balance"
                type="number"
                name="initialBalance"
                onChange={handleChange}
                onKeyDown={handleKeys}
              />
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => setAccountForm(false)}
                className="text-slate-400 place-self-center text-xl hover:text-slate-700 cursor-pointer"
              />
              <FontAwesomeIcon
                icon={faFloppyDisk}
                onClick={handleSubmit}
                type="submit"
                disabled={fullAccount() ? false : true}
                className={
                  fullAccount()
                    ? "place-self-center text-xl text-slate-600 cursor-pointer hover:text-slate-400"
                    : "place-self-center text-xl text-slate-300"
                }
              />
            </form>
          ) : (
            <div className="items-center">
              <button
                onClick={() => setAccountForm(true)}
                className="text-center text-sm font-bold text-slate-500 hover:text-slate-800"
              >
                ADD NEW ACCOUNT
              </button>
            </div>
          )*/}
        <div className="items-center grid grid-cols-10 p-2 font-bold text-white text-lg bg-gradient-to-t from-slate-600 to-slate-400 max-sm:rounded-t-lg">
          <div className="col-span-2 text-sm">TYPE</div>
          <div className="col-span-4 text-sm max-sm:text-center">NAME</div>
          <div className="col-span-2 flex-col align-middle text-xs">
            <div className="text-center">INITIAL</div>
            <div className="text-center">BALANCE</div>
          </div>
          <div className="text-center text-xs max-sm:hidden">EDIT</div>
          <div className="text-center text-xs max-sm:hidden">REMOVE</div>
        </div>
        {loading ? (
          <div className="flex min-w-full min-h-full items-center place-content-center mt-8">
            <FontAwesomeIcon
              icon={faSpinner}
              className="flex-1 text-4xl text-slate-600 animate-spin"
            />
          </div>
        ) : (
          <div>
            {accounts
              ? accounts.map((item, i) => {
                  return (
                    <AccountItem
                      key={i}
                      accountRow={item}
                      showModal={() => showAccountRemovalModal({id: item.id, type: item.type, name: item.name})}
                    />
                  );
                })
              : null}
          </div>
        )}
      </div>
    </>
  );
}
