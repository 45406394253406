import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileEdit,
  faTrashCan,
  faCheck,
  faXmark,
  faBuildingColumns,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
// import { currency } from "./utils";
import { supabase } from "../lib/supabaseClient";

export default function AccountItem({
  accountRow,
  showModal
}) {

  const [enableEditAccount, setEnableEditAccount] = useState(false);
  const [editRow, setEditRow] = useState(accountRow);

  const editAccount = () => setEnableEditAccount((state) => !state);

  const handleSaveEdit = () => {
    updateColumn(editRow);
    setEnableEditAccount(false);
  };

  const handleChange = (event) => {
    setEditRow({ ...editRow, [event.target.name]: event.target.value });
  };

  const handleCancelEdit = () => {
    setEditRow(accountRow);
    setEnableEditAccount(false);
  };

  const updateColumn = async (row) => {
    if (JSON.stringify(editRow) === JSON.stringify(accountRow)) return;
    try {
      const { data: accounts, error } = await supabase
        .from("accounts")
        .update({
          type: row.type,
          name: row.name,
          initialBalance: row.initialBalance,
        })
        .eq("id", row.id);
        if (error) throw error
    } catch (error) {
      console.log("error@accounts:", error);
    }
  };

  const typeIcon = (type) => {
    let icon;
    if (type === "bank") icon = faBuildingColumns;
    if (type === "creditcard") icon = faCreditCard;
    if (type === "investments") icon = faSackDollar;
    return icon;
  };

  return (
    <form
      className={`grid grid-cols-10 p-3 text-sm max-sm:text-xs even:bg-white odd:bg-slate-100 ${
        enableEditAccount ? "even:bg-slate-300 odd:bg-slate-300" : ""
      }`}
      onSubmit={(event) => event.preventDefault()}
    >
      <div className="col-span-2 flex items-center">
        {enableEditAccount ? null : (
          <FontAwesomeIcon
            icon={typeIcon(accountRow.type)}
            className="flex-0 text-slate-500"
          />
        )}
        {enableEditAccount ? (
          <select
            className="text-xs"
            name="type"
            onChange={handleChange}
            value={editRow.type}
          >
            <option value="bank">Bank</option>
            <option value="creditcard">Credit Card</option>
            <option value="investments">Investments</option>
          </select>
        ) : (
          <span className="flex-1 ml-2 text-slate-600 max-sm:text-xs max-[900px]:hidden">
            {editRow.type.toUpperCase()}
          </span>
        )}
      </div>
      <input
        className="col-span-4 max-sm:text-center"
        autoComplete="off"
        type="text"
        name="name"
        value={editRow.name.toUpperCase()}
        onChange={handleChange}
        disabled={!enableEditAccount}
      />
      <input
        className="col-span-2 text-center"
        autoComplete="off"
        type="number"
        name="initialBalance"
        value={
          editRow.initialBalance === 0
            ? 0
            : editRow.initialBalance
        }
        onChange={handleChange}
        disabled={!enableEditAccount}
      />
      <div className="text-center max-sm:text-right">
        {enableEditAccount ? (
          <FontAwesomeIcon
            icon={faXmark}
            className="text-[1rem] text-slate-500 hover:text-slate-300 hover:cursor-pointer max-sm:text-right"
            onClick={handleCancelEdit}
          />
        ) : (
          <FontAwesomeIcon
            className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-sm:text-right"
            icon={faFileEdit}
            onClick={editAccount}
          />
        )}
      </div>
      <div className="text-center max-sm:text-right">
        {enableEditAccount ? (
          <FontAwesomeIcon
            icon={faCheck}
            className="text-[1rem] text-slate-500 hover:text-slate-300 hover:cursor-pointer max-sm:text-right"
            onClick={handleSaveEdit}
          />
        ) : (
          <FontAwesomeIcon
            className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-sm:text-right"
            icon={faTrashCan}
            onClick={showModal}
            disabled={enableEditAccount}
          />
        )}
      </div>
    </form>
  );
}
