import React from "react";
import { registerables } from "chart.js/auto";
import { ReactChart } from "chartjs-react";

export default function HomeBalanceChart({ info }) {
  
  ReactChart.register(...registerables)

  let labels = []
  let values = []

  if (info) {
    labels = info?.map(item => item.date)
    values = info?.map(item => item.value.replaceAll('.','').replaceAll(',','.'))
  }

  const chartData = {
    labels: labels,
    datasets: [{
      label: 'Balance',
      data: values,
      borderColor: 'rgba(7, 172, 18, 0.8)',
      backgroundColor: 'rgba(7, 172, 18, 0.1)',
      fill: 'origin',
      tension: 0.2
    }],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }
      },
      title: {
        display: false,
        text: 'BALANCES',
        position: 'top',
        padding: {
          top: 0,
        },
      },
    },
    responsive: true,
    maintainAspectRadio: false,
    scales: {
      x: {
        display: false,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        border: {
          display: false,
        },
        grid: {
          display: true,
        },
        suggestedMin: 0,
        //max: 50000,
      }
    }
  }

  return (
    <ReactChart
      type='line'
      data={chartData}
      options={options}
      height={"120px"}
      width={"1200px"}
    />
  )
}
