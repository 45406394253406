import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserContext } from "../Context";
import { TransactionContext } from "../contexts/TransactionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightArrowLeft,
  faSpinner,
  faArrowUpWideShort,
  faArrowDownShortWide,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import TransactionItem from "./TransactionItem";

export default function Transactions() {
  const { userSession } = useContext(UserContext);

  const { accounts, categories, transactions, setTransactions } =
    useContext(TransactionContext);

  const [selectAccounts, setSelectAccounts] = useState(null);
  const [selectCategories, setSelectCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("down");
  // const [filterWord, setFilterWord] = useState(null);
  const [filteredList, setFilteredList] = useState(null);

  useEffect(() => {
    if (transactions) {
      setFilteredList(transactions);
      setSelectAccounts(accounts);
      setSelectCategories(categories);
      setLoading(false);
      // if (sortType) setSortType(null);
      // if (order) setOrder('down')
      // if (filterWord) setFilterWord(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession, transactions]);

  useEffect(() => {
    sortTransactions(transactions, sortType, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  const sortingOptions = [
    { value: "accountName", text: "Account" },
    { value: "amount", text: "Amount" },
    { value: "categoryName", text: "Category" },
    { value: "description", text: "Description" },
    { value: "duedate", text: "Due Date" },
  ];

  const [sortType, setSortType] = useState(sortingOptions[4].value);

  const sortTransactions = (list, type, order) => {
    const types = {
      accountName: "account",
      amount: "amount",
      categoryName: "category",
      created_at: "created_at",
      description: "description",
      duedate: "duedate",
    };
    const sortProperty = types[type];

    return sortProperty === "created_at" ||
      sortProperty === "description" ||
      sortProperty === "duedate"
      ? order === "up"
        ? setFilteredList(
          list?.sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]))
        )
        : setFilteredList(
          list?.sort((a, b) => b[sortProperty].localeCompare(a[sortProperty]))
        )
      : order === "up"
        ? setFilteredList(list?.sort((a, b) => a[sortProperty] - b[sortProperty]))
        : setFilteredList(
          list?.sort((a, b) => b[sortProperty] - a[sortProperty])
        );
  };

  const filterTransactions = (event) => {
    let updatedList = transactions;
    updatedList = updatedList.filter((item) => {
      const account = item.accountName.toLowerCase();
      const category = item.categoryName.toLowerCase();
      const duedate = item.duedate;
      const description = item.description.toLowerCase();
      const amount = item.amount.toString();

      return account.includes(event.target.value) ||
        category.includes(event.target.value) ||
        description.includes(event.target.value) ||
        duedate.includes(event.target.value) ||
        amount.includes(event.target.value)
        ? item
        : null;
    });
    setFilteredList(updatedList);
  };

  // const toggleTransactionForm = () => setTransactionForm((state) => !state);

  // const getTransactions = async (id) => {
  //   try {
  //     const { data: transactions, error } = await supabase
  //       .from("transactions")
  //       .select(
  //         `
  //         id,
  //         created_at,
  //         account,
  //         accounts(name),
  //         category,
  //         categories(type,name),
  //         description,
  //         duedate,
  //         amount
  //         `
  //       )
  //       .eq("user_id", id)
  //       .order("duedate", { ascending: false });
  //     if (error) throw error;
  //
  //     const transactionsList = transactions.map((item) => {
  //       return {
  //         id: item.id,
  //         created_at: item.created_at,
  //         account: item.account,
  //         accountName: item.accounts.name,
  //         category: item.category,
  //         categoryName: item.categories.name,
  //         categoryType: item.categories.type,
  //         description: item.description,
  //         amount: item.amount,
  //         duedate: item.duedate,
  //       };
  //     });
  //     setTransactions(transactionsList);
  //     setFilteredList(transactionsList)
  //     // console.log(transactionsList)
  //   } catch (error) {
  //     alert(error.error_description || error.message);
  //   }
  // };

  const removeTransaction = async (id) => {
    const message = window.confirm(
      `Are you sure you want to delete this transaction?`
    );
    if (message) {
      setTransactions(transactions.filter((item) => item.id !== id));
      setFilteredList(transactions.filter((item) => item.id !== id));
      try {
        // eslint-disable-next-line
        const { data, error } = await supabase
          .from("transactions")
          .delete()
          .eq("id", id);
        if (error) throw error;
      } catch (error) {
        console.log("error@transactions:", error);
      }
    } else {
      return;
    }
  };

  const updateTransaction = (event, row) => {
    const index = transactions.findIndex((item) => item.id === row.id);
    const temp = [...transactions];
    temp[index] = { ...temp[index], [event.target.name]: event.target.value };
    setFilteredList(temp);
  };

  const handleOrderingReset = () => {
    sortTransactions(filteredList, "duedate", "down");
    setSortType(sortingOptions[4].value);
    setOrder("down");
  };

  // <div>
  //   {transactionForm ? (
  //     <form
  //       key="form"
  //       onSubmit={handleSubmit}
  //       className="grid grid-cols-9 gap-4 text-sm items-center text-slate-500 border-b-2 border-slate-300 max-[680px]:grid-cols-1 max-[680px]:grid-rows-5"
  //     >
  //       <select
  //         required
  //         className="text-center ml-2"
  //         name="type"
  //         ref={selectTypeInput}
  //         onChange={handleChange}
  //         defaultValue={""}
  //       >
  //         <option value="" disabled>
  //           select type
  //         </option>
  //         <option value="income">Income</option>
  //         <option value="expense">Expense</option>
  //       </select>
  //       <input
  //         className=" text-center"
  //         type="date"
  //         name="duedate"
  //         onKeyDown={handleKeys}
  //         onChange={handleChange}
  //       />
  //       <input
  //         required
  //         className="col-span-2 text-center "
  //         autoComplete="off"
  //         placeholder="enter description of transaction"
  //         type="text"
  //         name="description"
  //         onChange={handleChange}
  //         onKeyDown={handleKeys}
  //       />
  //       <input
  //         required
  //         className="text-center "
  //         placeholder="enter amount"
  //         type="number"
  //         step={0.01}
  //         name="amount"
  //         onChange={handleChange}
  //         onKeyDown={handleKeys}
  //       />
  //       <select
  //         required
  //         className="text-center "
  //         name="category"
  //         onChange={handleChange}
  //         defaultValue={""}
  //       >
  //         <option value="" disabled>
  //           select category
  //         </option>
  //         {renderCategories()}
  //       </select>
  //       <select
  //         required
  //         className="text-center "
  //         name="account"
  //         onChange={handleChange}
  //         defaultValue={""}
  //       >
  //         <option value="" disabled>
  //           select account
  //         </option>
  //         {renderAccounts()}
  //       </select>
  //       <button onClick={() => setTransactionForm(false)}>
  //         <FontAwesomeIcon
  //           icon={faXmark}
  //           className="text-slate-400 place-self-center text-xl hover:text-slate-700 cursor-pointer"
  //         />
  //       </button>
  //       <button onClick={handleSubmit}>
  //         <FontAwesomeIcon
  //           icon={faFloppyDisk}
  //           type="submit"
  //           disabled={fullTransaction() ? false : true}
  //           className={
  //             fullTransaction()
  //               ? "place-self-center text-xl text-slate-600 cursor-pointer hover:text-slate-400"
  //               : "place-self-center text-xl text-slate-300"
  //           }
  //         />
  //       </button>
  //     </form>
  //   ) : (
  //     <div className="flex items-center">
  //       <span className="flex-1">
  //         <button
  //           onClick={() => setTransactionForm(true)}
  //           className="text-left text-sm font-bold text-slate-500 hover:text-slate-800"
  //         >
  //           ADD NEW TRANSACTION
  //         </button>
  //       </span>
  return (
    <div className="">
      <div className="flex p-4 bg-slate-200">
        <div className="flex-1 flex items-center gap-0 bg-white shadow mr-16">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="flex-0 text-slate-400 ml-2"
          />
          <input
            onChange={filterTransactions}
            className="flex-1 text-center text-xs py-2"
            placeholder="search here"
          />
        </div>
        <div className="flex-0 w-8 items-center mx-2">
          {sortType !== null && sortType !== "duedate" ? (
            <button
              className="flex-0 text-[.6rem] text-slate-400 font-bold hover:text-slate-600"
              onClick={handleOrderingReset}
            >
              RESET
            </button>
          ) : null}
        </div>
        <div className="flex-0 mx-2">
          <select
            onChange={(event) =>
              setSortType(() => {
                sortTransactions(filteredList, event.target.value, order);
                const index = sortingOptions.findIndex(
                  (item) => item.value === event.target.value
                );
                return sortingOptions[index].value;
              })
            }
            className="text-sm py-2 px-1 mr-2 text-slate-500 text-center cursor-pointer hover:text-slate-700 bg-white rounded-lg shadow"
            value={sortType ?? sortingOptions[4].value}
          >
            {sortingOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-0 flex flex-col">
          <FontAwesomeIcon
            icon={faArrowUpWideShort}
            className={`text-xl align-bottom cursor-pointer hover:text-slate-500 ${order === "up" ? "text-slate-700" : "text-slate-400"
              }`}
            onClick={() =>
              setOrder(() => {
                sortTransactions(filteredList, sortType, "up");
                return "up";
              })
            }
          />
          <FontAwesomeIcon
            icon={faArrowDownShortWide}
            className={`text-xl align-top cursor-pointer hover:text-slate-500 ${order === "down" ? "text-slate-700" : "text-slate-400"
              }`}
            onClick={() =>
              setOrder(() => {
                sortTransactions(filteredList, sortType, "down");
                return "down";
              })
            }
          />
        </div>
      </div>

      <div className="sticky top-0 items-center grid grid-cols-15 p-2 font-bold text-white bg-gradient-to-t from-slate-600 to-slate-400 min-h-[56px] max-[600px]:grid-cols-9">
        <div className="">
          <FontAwesomeIcon
            icon={faArrowRightArrowLeft}
            className="ml-2 text-left"
          />
        </div>
        <div className="col-span-2">DUE DATE</div>
        <div className="col-span-4">DESCRIPTION</div>
        <div className="col-span-2 text-right">AMOUNT</div>
        <div className="col-span-2 text-right max-[600px]:hidden">CATEGORY</div>
        <div className="col-span-2 text-right max-[600px]:hidden">ACCOUNT</div>
        <div className="text-right text-xs max-[600px]:hidden">EDIT</div>
        <div className="text-right text-xs max-[600px]:hidden">REMOVE</div>
      </div>
      {loading ? (
        <div className="flex min-w-full min-h-full items-center place-content-center mt-8">
          <FontAwesomeIcon
            icon={faSpinner}
            className="flex-1 text-4xl text-slate-600 animate-spin"
          />
        </div>
      ) : (
        <div>
          {filteredList
            ? filteredList.map((item, i) => {
              return (
                <TransactionItem
                  key={i}
                  transactionRow={item}
                  removeTransaction={() => removeTransaction(item.id)}
                  updateTransaction={updateTransaction}
                  selectAccounts={selectAccounts}
                  selectCategories={selectCategories}
                />
              );
            })
            : null}
        </div>
      )}
    </div>
  );
}
