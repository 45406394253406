import { createContext, useEffect, useState, useContext } from "react";
import { supabase } from "../lib/supabaseClient";
import { UserContext } from "../Context";
import { sortList } from "../components/utils.js";

export const TransactionContext = createContext();

export function TransactionContextProvider({ children }) {
  const { userSession } = useContext(UserContext);

  const [accounts, setAccounts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [transfers, setTransfers] = useState(null);

  useEffect(() => {
    if (userSession) {
      (async () => {
        await getTransactions(userSession.user.id);
        await getAccounts(userSession.user.id);
        await getCategories(userSession.user.id);
        await getTransfers(userSession.user.id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession]);

  const getTransactions = async (id) => {
    try {
      const { data: transactions, error } = await supabase
        .from("transactions")
        .select(
          `
          id,
          created_at,
          account,
          accounts(name),
          category,
          categories(type,name),
          description,
          duedate,
          amount
          `
        )
        .eq("user_id", id)
        .order("duedate", { ascending: false });

      if (error) throw error;

      const transactionsList = transactions.map((item) => {
        return {
          id: item.id,
          created_at: item.created_at,
          account: item.account,
          accountName: item.accounts.name,
          category: item.category,
          categoryName: item.categories.name,
          categoryType: item.categories.type,
          description: item.description,
          amount: item.amount,
          duedate: item.duedate,
        };
      });
      setTransactions(transactionsList);
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const getCategories = async (id) => {
    try {
      const { data: categories, error } = await supabase
        .from("categories")
        .select("id,type,name")
        .eq("user_id", id);

      if (error) throw error;

      setCategories(sortList(categories, "ascending"));
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const getAccounts = async (id) => {
    try {
      const { data: accounts, error } = await supabase
        .from("accounts")
        .select("*")
        .eq("user_id", id);

      if (error) throw error;

      setAccounts(sortList(accounts, "descending"));
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  const getTransfers = async (id) => {
    try {
      const { data: transfers, error } = await supabase
        .from("transfers")
        .select("*")
        .eq("user_id", id);
      if (error) throw error;
      setTransfers(transfers);
      // console.log("transfers =>", transfers);
    } catch (error) {
      console.log("error@transfers:", error);
    }
  };

  const updateTransactionItem = async (row) => {
    // if (editRow === transactionRow) return;
    try {
      const { data, error } = await supabase
        .from("transactions")
        .update({
          account: row.account,
          category: row.category,
          name: row.name,
          description: row.description,
          duedate: row.duedate,
          amount: row.amount,
        })
        .eq("id", row.id);

      if (error) throw error;

      getTransactions(userSession.user.id);
    } catch (error) {
      console.log("error@transactions:", error);
    }
  };

  return (
    <TransactionContext.Provider
      value={{
        accounts,
        categories,
        transactions,
        setAccounts,
        setCategories,
        setTransactions,
        updateTransactionItem,
        transfers,
        setTransfers,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
}
