import React, { useEffect, useState, useContext } from "react";
// import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faFileEdit,
  faTrashCan,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { supabase } from "../lib/supabaseClient";
import { today } from "./utils";
import { TransactionContext } from "../contexts/TransactionContext";

export default function TransactionItem({
  transactionRow,
  updateTransaction,
  removeTransaction,
  selectAccounts,
  selectCategories,
}) {

  const { updateTransactionItem } = useContext(TransactionContext)

  const [enableEditTransaction, setEnableEditTransaction] = useState(false);
  const [editRow, setEditRow] = useState(transactionRow);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    if (enableEditTransaction) {
      setSelectedType(transactionRow.categoryType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableEditTransaction]);

  const editTransaction = () => setEnableEditTransaction((state) => !state);

  const handleSaveEdit = (event) => {
    if (editRow.duedate === "") {
      alert("Cannot edit transaction without a DUE DATE!");
      return;
    }
    updateTransaction(event, editRow)
    updateColumn(editRow);
    setEnableEditTransaction(false);
    setEditRow(editRow);
  };

  const handleChange = (event) => {
    setEditRow({ ...transactionRow, [event.target.name]: event.target.value });
    updateTransaction(event, editRow);
  };

  const handleTypeChange = (event) => {
    handleChange(event);
    setSelectedType(event.target.value);
  };

  const handleCancelEdit = (event) => {
    setEditRow(transactionRow);
    setEnableEditTransaction(false);
    updateTransaction(event, transactionRow);
  };

  const updateColumn = async (row) => {
    if (row === transactionRow) return;
    updateTransactionItem(row)
    // try {
    //   const { data, error } = await supabase
    //     .from("transactions")
    //     .update({
    //       account: row.account,
    //       category: row.category,
    //       name: row.name,
    //       description: row.description,
    //       duedate: row.duedate,
    //       amount: row.amount,
    //     })
    //     .eq("id", row.id);
    //   if (error) throw error;
    // } catch (error) {
    //   console.log("error@transactions:", error);
    // }
  };

  const renderCategories = () => {
    return selectCategories
      ? selectCategories
        .filter((item) => item.type === selectedType)
        .map((item) => {
          return <option key={item.id} value={item.id}>{item.name.toUpperCase()}</option>;
        })
      : null;
  };

  const renderAccounts = () => {
    return selectAccounts
      ? selectAccounts.map((item) => (
        <option key={item.id} value={item.id}>
          [ {item.type.toUpperCase()} ] {item.name.toUpperCase()}
        </option>
      ))
      : null;
  };

  return (
    <form
      className={`grid grid-cols-15 p-2 text-xs items-center even:bg-white odd:bg-slate-100 max-[600px]:grid-cols-9 ${enableEditTransaction ? 'even:bg-slate-300 odd:bg-slate-300' : ''}`}
      key={transactionRow.id}
    >
      {enableEditTransaction ? (
        <select
          name="type"
          onChange={handleTypeChange}
          value={editRow.categoryType}
        >
          <option value="income">INCOME</option>
          <option value="expense">EXPENSE</option>
        </select>
      ) : (
        <FontAwesomeIcon
          className={
            transactionRow.categoryType === "income"
              ? "w-8 h-4 text-green-600"
              : "w-8 h-4 text-rose-600"
          }
          icon={
            transactionRow.categoryType === "income" ? faArrowUp : faArrowDown
          }
        />
      )}
      {enableEditTransaction ? (
        <input
          className="col-span-2"
          autoComplete="off"
          type="date"
          name="duedate"
          value={editRow?.duedate?.slice(0, 10) ?? today}
          onChange={handleChange}
        />
      ) : (
        <span className="col-span-2">
          {editRow?.duedate?.slice(0, 10) ?? today}
        </span>
      )}
      {enableEditTransaction ? (
        <input
          className="col-span-4 bg-transparent"
          autoComplete="off"
          type="text"
          name="description"
          value={editRow.description}
          onChange={handleChange}
          disabled={!enableEditTransaction}
        />
      ) : (
        <span className="col-span-4 flex-wrap">
          {editRow.description}
        </span>
      )}
      {enableEditTransaction ? (
        <input
          className="col-span-2 text-right bg-transparent"
          autoComplete="off"
          type="number"
          name="amount"
          value={editRow.amount}
          onChange={handleChange}
          disabled={!enableEditTransaction}
        />
      ) : (
        <span className="col-span-2 text-right flex-wrap">
          {editRow.amount}
        </span>
      )}
      {enableEditTransaction ? (
        <select
          required
          className="col-span-2 text-right max-[600px]:hidden"
          name="category"
          onChange={handleChange}
          defaultValue={
            editRow?.categories?.id ?? editRow.category
          }
          disabled={!enableEditTransaction}
        >
          {renderCategories()}
        </select>
      ) : (
        <span className="col-span-2 text-right max-[600px]:hidden">
          {editRow?.categoryName?.toUpperCase()}
        </span>
      )}
      {/*<span className="col-span-2 text-right">
        {transactionRow?.created_at?.slice(0, 10) ?? today}
      </span>*/}
      {enableEditTransaction ? (
        <select
          required
          className="col-span-2 text-right max-[600px]:hidden"
          name="account"
          onChange={handleChange}
          defaultValue={editRow.account}
        >
          <option value="" disabled>
            select account
          </option>
          {renderAccounts()}
        </select>
      ) : (
        <span className="col-span-2 text-right max-[600px]:hidden">
          {editRow?.accountName?.toUpperCase()}
        </span>
      )}
      <div className="text-right text-[1rem] max-[600px]:hidden">
        {enableEditTransaction ? (
          <FontAwesomeIcon
            icon={faXmark}
            className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-[680px]:text-sm"
            onClick={handleCancelEdit}
          />
        ) : (
          <FontAwesomeIcon
            className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-[680px]:text-sm"
            icon={faFileEdit}
            onClick={editTransaction}
          />
        )}
      </div>
      <div className="text-right text-[1rem] max-[600px]:hidden">
        {enableEditTransaction ? (
          <FontAwesomeIcon
            icon={faCheck}
            className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-[680px]:text-sm"
            onClick={handleSaveEdit}
          />
        ) : (
          <FontAwesomeIcon
            className="text-slate-500 hover:text-slate-300 hover:cursor-pointer"
            icon={faTrashCan}
            onClick={removeTransaction}
            disabled={enableEditTransaction}
          />
        )}
      </div>
    </form>
  );
}
