const newDate = new Date();
export const today = newDate.toISOString().substring(0, 10);

export const reducer = (list) => list.reduce((a, b) => a + Number(b.amount), 0);
// export const reducer = (list,key) => list.reduce((a, b) => a + Number(b[key]), 0);

export const getCurrentTimePeriod = () => {
  const today = new Date();
  const year = String(today.getFullYear());
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const firstDay = String(
    new Date(today.getFullYear(), today.getMonth(), 1)
  ).slice(8, 10);
  const lastDay = String(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  ).slice(8, 10);
  const res = [`${year}-${month}-${firstDay}`, `${year}-${month}-${lastDay}`];
  return res;
};

export const getFirstOrLastDayOfMonth = (input, choice) => {
  if (choice === "first" || choice === "last") {
    const firstOrLast = choice === "first" ? 0 : 1;
    const inputDate = input.split("-");
    const date = new Date(inputDate[0], inputDate[1], firstOrLast);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const res = `${date.getFullYear()}-${month}-${day}`;
    return res;
  }
};

export const money = (num) =>
  num.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
export const currency = (num) =>
  num.toLocaleString("pt-br", { minimumFractionDigits: 0 });

export const time = (month, list) => {
  return list.filter(
    (item) =>
      item.transactionDuedate[5] === month[0] &&
      item.transactionDuedate[6] === month[1]
  );
};

export const findAccount = (id, accounts) =>
  accounts.find((item) => item.id === id);

export const initialBalance = (id, accounts) =>
  Number(findAccount(id, accounts).initialBalance);

export const incomePerAccount = (id, list) =>
  list.filter((item) => item.account === id && item.type === "income");

export const expensePerAccount = (id, list) =>
  list.filter((item) => item.account === id && item.type === "expense");

export const incomePerCategory = (id, list) =>
  list.filter((item) => item.category === id && item.type === "income");

export const expensePerCategory = (id, list) =>
  list.filter((item) => item.category === id && item.type === "expense");

export const thisMonth = (id, list) =>
  reducer(incomePerAccount(id, list)) - reducer(expensePerAccount(id, list));

export const accountBalance = (id, accounts, list) => {
  if (list.length) {
    return (
      reducer(incomePerAccount(id, list)) +
      initialBalance(id, accounts) -
      reducer(expensePerAccount(id, list))
    );
  }
};

export const total = (type, list) => {
  return type === "income"
    ? list.filter((item) => item.type === "income")
    : list.filter((item) => item.type === "expense");
};

export const reduceAccount = (list, type, account) =>
  list
    .filter((item) => item.type === type && item.account === account)
    .reduce((a, b) => a + b.amount, 0);

export const reduceCategory = (list, type, category) =>
  list
    .filter((item) => item.type === type && item.category === category)
    .reduce((a, b) => a + b.amount, 0);

export const totalTransactions = (list, type) =>
  list.filter((item) => item.type === type).reduce((a, b) => a + b.amount, 0);

export const totalTransactionsUntilToday = (list, day) => {
  const filtered = list.filter((item) => item.duedate <= day);
  const income = totalTransactions(filtered, "income");
  const expense = totalTransactions(filtered, "expense");
  return income - expense;
};

export const getBalanceUntilDate = (list, name, day) => {
  const filtered = list.filter(
    (item) => item.duedate <= day && item.account === name
  );
  const income = totalTransactions(filtered, "income");
  const expense = totalTransactions(filtered, "expense");
  return income - expense;
};

export const getAllBalancesUntilDate = (accountList, list, day) => {
  const initial = totalInitialBalance(accountList);
  const filtered = list.filter((item) => item.duedate <= day);
  const income = totalTransactions(filtered, "income");
  const expense = totalTransactions(filtered, "expense");
  return initial + income - expense;
};

export const getTransfersUntilDate = (list, id, day) => {
  const fromFilter = list.filter((item) => {
    const trigger = item.due_date
      ? item.due_date.slice(0, 10)
      : item.created_at.slice(0, 10);
    return trigger <= day && item.from === id;
  });
  const toFilter = list.filter((item) => {
    const trigger = item.due_date
      ? item.due_date.slice(0, 10)
      : item.created_at.slice(0, 10);
    return trigger <= day && item.from === id;
  });
  const fromReduced = fromFilter
    ? fromFilter.reduce((a, b) => a + b.amount, 0)
    : 0;
  const toReduced = toFilter ? toFilter.reduce((a, b) => a + b.amount, 0) : 0;
  return toReduced - fromReduced;
};

export const netBalance = (list, account) =>
  reducer(incomePerAccount(account, list)) -
  reducer(expensePerAccount(account, list));

export const filterByDate = (list, startDate, endDate) =>
  list?.filter((item) => item.duedate >= startDate && item.duedate <= endDate);

export const totalInitialBalance = (list) =>
  list.reduce((a, b) => a + b.initialBalance, 0);

export const sortList = (list, order) =>
  order === "ascending"
    ? list.sort(
      (a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name)
    )
    : list.sort(
      (a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
    );

export const makeBalanceArray = (accountList, list) => {
  return [...new Set(list.map((item) => item.duedate))].map((item) => {
    return {
      date: item,
      value: currency(getAllBalancesUntilDate(accountList, list, item)),
    };
  });
};
