import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSackDollar,
  faRightFromBracket,
  faHouse,
  faFolderOpen,
  faTag,
  faRightLeft,
  faQuestion,
  faUser,
  faPlus,
  faArrowLeft,
  faHandHoldingDollar,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { supabase } from "../lib/supabaseClient";
import DropdownMenu from "./DropdownMenu";

export default function Navbar({ toggleHelpModal, toggleTransferModal }) {
  const handleSignOut = async () => {
    const message = window.confirm(`Are you sure you want to log out?`);
    if (message) {
      const { error } = await supabase.auth.signOut();
      console.log("error:", error);
    }
  };

  const [showUserText, setShowUserText] = useState(false);

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const toggleDropdownMenu = () => {
    showForm ? setShowForm(false) : setShowDropdownMenu(!showDropdownMenu);
  };
  // const [showMobileMenu, setShowMobileMenu] = useState(false);

  // const mql = window.matchMedia("(max-width: 600px)");
  // let mobileView = mql.matches;

  // const handleShowMenu = () => setShowMobileMenu((state) => !state);

  // <div className="flex-0 bottom-0 flex z-10 w-full bg-white h-12 items-center justify-center overflow-hidden">
  //   <span className="flex-1 text-center">
  //     <button className="flex-0">
  //       <FontAwesomeIcon
  //         className="p-4 text-3xl text-slate-400 hover:text-slate-800"
  //         icon={faBars}
  //         onClick={handleShowMenu}
  //       />
  //     </button>
  //   </span>
  // </div>

  return (
    <div className="w-64 flex-grow-0 overflow-hidden flex flex-col gap-2">
      <div className="flex-0 flex items-center p-8 shadow bg-gradient-to-b from-slate-400 to-white rounded-br-2xl">
        <div className="flex-1 flex flex-col">
          <div className="flex-1 text-center text-slate-700 font-bold tracking-widest justify-end">
            <span className="text-slate-300">.</span>SIMPLE
          </div>
          <div className="flex-1 text-center text-slate-500 font-bold tracking-wide justify-start">
            FINANCE
          </div>
        </div>
        <FontAwesomeIcon
          className="flex-1 w-10 h-10 text-slate-500"
          icon={faSackDollar}
        />
      </div>
      <nav className="flex-1 flex flex-col py-4 text-sm shadow bg-white rounded-tr-2xl">
        <div className="flex flex-col items-center justify-center mb-8">
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-slate-100">
            <button onClick={toggleDropdownMenu} onKeyDown={toggleDropdownMenu}>
              <FontAwesomeIcon
                className="text-3xl text-slate-400 hover:text-slate-800 p-4"
                icon={showDropdownMenu ? faArrowLeft : faPlus}
              />
            </button>
          </div>
        </div>
        {showDropdownMenu ? (
          <DropdownMenu
            showForm={showForm}
            setShowForm={setShowForm}
            setShowDropdownMenu={setShowDropdownMenu}
          />
        ) : (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "flex items-center h-12 text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
                  : "flex items-center h-12 text-slate-400 hover:text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
              }
              to="/"
            >
              <FontAwesomeIcon className="flex-[0.5] h-4" icon={faHouse} />
              <span className="flex-[1.5] font-bold">HOME</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "flex items-center h-12 text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
                  : "flex items-center h-12 text-slate-400 hover:text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
              }
              to="/accounts"
            >
              <FontAwesomeIcon className="flex-[0.5] h-4" icon={faFolderOpen} />
              <span className="flex-[1.5] font-bold">ACCOUNTS</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "flex items-center h-12 text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
                  : "flex items-center h-12 text-slate-400 hover:text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
              }
              to="/categories"
            >
              <FontAwesomeIcon className="flex-[0.5] h-4" icon={faTag} />
              <span className="flex-[1.5] font-bold">CATEGORIES</span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "flex items-center h-12 text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
                  : "flex items-center h-12 text-slate-400 hover:text-slate-600 transform hover:translate-x-2 transition-transform ease-in duration-200"
              }
              to="/transactions"
            >
              <FontAwesomeIcon
                className="flex-[0.5] h-4"
                icon={faMoneyBillTransfer}
              />
              <span className="flex-[1.5] font-bold">TRANSACTIONS</span>
            </NavLink>
            <div
              className="flex h-12 items-center text-slate-400 hover:text-slate-600 cursor-pointer transform hover:translate-x-2 transition-transform ease-in duration-200"
              onClick={toggleTransferModal}
            >
              <FontAwesomeIcon className="flex-[0.5] h-4" icon={faRightLeft} />
              <span className="flex-[1.5] font-bold">TRANSFERS</span>
            </div>
            <div
              className="mt-8 flex h-12 items-center text-slate-400 hover:text-slate-600 cursor-pointer transform hover:translate-x-2 transition-transform ease-in duration-200"
              onClick={toggleHelpModal}
            >
              <FontAwesomeIcon className="flex-[0.5] h-4" icon={faQuestion} />
              <span className="flex-[1.5] font-bold">HELP</span>
            </div>
            <div
              className="mt-8 flex h-12 items-center text-slate-400 hover:text-slate-600 cursor-pointer transform hover:translate-x-2 transition-transform ease-in duration-200"
              onClick={handleSignOut}
            >
              <FontAwesomeIcon
                className="flex-[0.5] h-4 rotate-180"
                icon={faRightFromBracket}
              />
              <span className="flex-[1.5] font-bold">SIGN OUT</span>
            </div>
            <div className="mt-16 flex-1 flex flex-col items-center justify-center">
              <div
                className="flex h-24 w-24 items-center rounded-full border-2 border-slate-400 bg-slate-400/10 hover:bg-transparent cursor-pointer"
                onMouseEnter={() => setShowUserText((state) => !state)}
                onMouseLeave={() => setShowUserText((state) => !state)}
              >
                <FontAwesomeIcon
                  className="flex-1 h-12 text-slate-400"
                  icon={faUser}
                />
              </div>
              <span className="mt-4 flex-1 text-slate-500 text-[.6rem] italic text-center mx-4">
                {showUserText
                  ? "Click on the user button above to personalize your experience!"
                  : null}
              </span>
            </div>
          </>
        )}
      </nav>
    </div>
  );
}
