import React, { createContext, useState, useRef } from "react";
import { supabase } from "./lib/supabaseClient";

export const UserContext = createContext();

export function UserContextProvider({ children }) {

  // const [accounts,setAccounts] = useState([]);
  const [userSession, setUserSession] = useState(null)

  const [helperText, setHelperText] = useState({ error: null, text: null });
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleLogin = async (type) => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    const { data: { session }, error } =
      type === "LOGIN"
        ? await supabase.auth.signInWithPassword({ email, password })
        : await supabase.auth.signUp({ email, password });

    if (session) setUserSession(session)

    if (error) {
      setHelperText({ error: true, text: error.message });
    } else if (!session && !error) {
      setHelperText({
        error: false,
        text: "An email has been sent to you for verification!",
      });
    }
  };

  // if (user) setAccounts(getAccounts(user.id))

  return (
    <UserContext.Provider value={{
      handleLogin,
      helperText,
      setHelperText,
      emailRef,
      passwordRef,
      userSession,
      setUserSession,
      // accounts,
    }}
    >
      {children}
    </UserContext.Provider>
  );
}
