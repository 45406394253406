export default function PageNotFound() {
  return (
    <>
      <div className="flex flex-col items-center">
        <span className="flex-1 text-4xl">Page not found!</span>
        <span className="flex-1 text-xs">
          try clicking one of the links on the left
        </span>
      </div>
    </>
  );
}
