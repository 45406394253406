import React, { useState } from "react";
import Form from "./Form";

export default function DropdownMenu({
  showForm,
  setShowForm,
  setShowDropdownMenu,
}) {
  const handleClick = (event) => {
    setShowForm(true);
    setFormType(event.target.value);
  };

  const [formType, setFormType] = useState(null);

  return (
    <div className="flex-1 flex flex-col gap-4 p-4">
      {showForm ? (
        <Form
          type={formType}
          setShowForm={setShowForm}
          setShowDropdownMenu={setShowDropdownMenu}
        />
      ) : (
        <>
          <span className="text-slate-600 text-xs mb-2">CREATE NEW :</span>
          <button
            className="h-12 text-sm text-slate-800 tracking-wider bg-slate-200 shadow rounded-lg hover:bg-slate-500 hover:text-white"
            value="account"
            onClick={handleClick}
          >
            ACCOUNT
          </button>
          <button
            className="h-12 text-sm text-slate-800 tracking-wider bg-slate-200 shadow rounded-lg hover:bg-slate-500 hover:text-white"
            value="category"
            onClick={handleClick}
          >
            CATEGORY
          </button>
          <button
            className="h-12 text-sm text-slate-800 tracking-wider bg-slate-200 shadow rounded-lg hover:bg-slate-500 hover:text-white"
            value="transaction"
            onClick={handleClick}
          >
            TRANSACTION
          </button>
        </>
      )}
    </div>
  );
}
