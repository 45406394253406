import React, { useEffect } from 'react'
import ReactDOM from "react-dom";

const modalRoot = document.getElementById('modal')

export default function Modal({ children }) {

  const div = document.createElement('div')

  useEffect(() => {
    modalRoot.appendChild(div)
    return ()=>  modalRoot.removeChild(div)
  },[])

 return ReactDOM.createPortal(children,modalRoot)
}
