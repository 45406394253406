import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function RenderModal({toggleHelpModal}) {
    return (
      <div className='fixed z-50 items-stretch bg-slate-200/50 min-h-screen overflow-y-auto'>
        <div className='text-right mr-12 mt-8 h-12'>
        <FontAwesomeIcon icon={faXmark} className='flex-1 max-h-12 text-6xl text-slate-500 hover:cursor-pointer hover:text-black' onClick={toggleHelpModal}/>
        </div>
      <h1 className='text-center text-3xl text-slate-600 h-8 mb-4'>TUTORIAL :  basic usage</h1>
        <div className='flex flex-col text-justify tracking-wide text-lg mx-48 my-8'>
          <span className='flex-1 indent-8 mb-4'>The basic idea for using this application is to setup your own accounts and categories, using the ACCOUNTS and CATEGORIES components to input names and types. These are later used in the TRANSACTIONS component to add transactions and the HOME component to gather information on what your finances look like.
          </span>
      <br />
          <span className='flex-1 indent-8 mb-4'>In the ACCOUNTS component you'll find the ADD NEW ACCOUNT button, where you will select the type ( Bank, Credit Card or Investments ), give it a name and input an initial balance for it. An initial balance is needed so you can start right away if you want to, using your current balance from that account. You can also just leave it at 0 (zero). Click on the floppy disk to SAVE the new account. You can then edit or remove it, if you wish.
          </span>
          <span className='flex-1 indent-8 mb-4'>The CATEGORIES component is even simpler, as you just need a name and a type ( income or expense ).
          </span>
          <span className='flex-1 indent-8 mb-4'>The action happens in the TRANSACTIONS component, where you use the accounts and categories you registered to assign income and expenses. The ADD NEW TRANSACTION button opens a form, where you need to select a type, enter an amount, select category and account. Due date and description are optional but otherwise encouraged. If you don't input a due date, the application will assume it is due today.
          </span>
          <span className='flex-1 indent-8'>And finally the HOME component has a dashboard that starts out showing the current month, but also lets you select a time frame to review your finances.
          </span>
        </div>
      </div>
    )
  }
