import React from "react";
import AccountForm from "./AccountForm";
import CategoryForm from "./CategoryForm";
import TransactionForm from "./TransactionForm";
import Transfers from "./Transfers.jsx"

export default function Form({
  type,
  setShowForm,
  setShowDropdownMenu,
}) {

  const renderForm = (type) => {
    if (type === "account")
      return (
        <AccountForm
          setShowForm={setShowForm}
          setShowDropdownMenu={setShowDropdownMenu}
        />
      );
    if (type === "category")
      return (
        <CategoryForm
          setShowForm={setShowForm}
          setShowDropdownMenu={setShowDropdownMenu}
        />
      );
    if (type === "transaction")
      return (
        <TransactionForm
          setShowForm={setShowForm}
          setShowDropdownMenu={setShowDropdownMenu}
        />
      );
    if (type === "transfers")
      return (
        <Transfers
          setShowForm={setShowForm}
          setShowDropdownMenu={setShowDropdownMenu}
        />
      );
  };

  return renderForm(type);
}
