import  { useEffect, useState } from "react";

export function useSettings() {

  const enums = {
    USER_SETTINGS_GOTOPAGE: 'user_settings_goToPage',
    USER_SETTINGS_CLOSEFORM: 'user_settings_closeForm',
  }

  const initGoToPage = JSON.parse(localStorage.getItem(enums.USER_SETTINGS_GOTOPAGE))
  const initCloseForm = JSON.parse(localStorage.getItem(enums.USER_SETTINGS_CLOSEFORM))

  const [userCloseForm, setUserCloseForm] = useState(initCloseForm ?? true);
  const [userGoToPage, setUserGoToPage] = useState(initGoToPage ?? true);

  useEffect(() => {
    localStorage.setItem(enums.USER_SETTINGS_GOTOPAGE,userGoToPage)
  },[userGoToPage,enums.USER_SETTINGS_GOTOPAGE])

  useEffect(() => {
    localStorage.setItem(enums.USER_SETTINGS_CLOSEFORM,userCloseForm)
  },[userCloseForm,enums.USER_SETTINGS_CLOSEFORM])

  return [userGoToPage, setUserGoToPage, userCloseForm, setUserCloseForm]
}
