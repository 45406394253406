import React, { useState, useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { supabase } from "./lib/supabaseClient";
import Auth from "./components/Auth";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Accounts from "./components/Accounts";
import Categories from "./components/Categories";
import Transactions from "./components/Transactions";
import PageNotFound from "./components/PageNotFound";
import HelpModal from "./components/HelpModal";
import Transfers from "./components/Transfers";
import { UserContext } from "./Context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

export default function App() {
  const { userSession, setUserSession } = useContext(UserContext);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUserSession(session);
    });
    supabase.auth.onAuthStateChange((_event, session) => {
      setUserSession(session);
    });
  }, []);

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const toggleHelpModal = () => setShowHelpModal(!showHelpModal);
  const toggleTransferModal = () => setShowTransferModal(!showTransferModal);

  return (
    <>
      {!userSession ? (
        <Auth />
      ) : (
        <>
          {showHelpModal ? (
            <HelpModal toggleHelpModal={toggleHelpModal} />
          ) : null}
          {showTransferModal ? (
            <Transfers toggleTransferModal={toggleTransferModal} />
          ) : null}
          <div
            className={`flex gap-4 min-h-screen ${showTransferModal ? "bg-slate-200" : "bg-slate-100"
              } ${showHelpModal ? "blur-sm" : ""}`}
          >
            <Navbar
              toggleHelpModal={toggleHelpModal}
              toggleTransferModal={toggleTransferModal}
            />
            <div className="flex-1 h-auto w-fit mr-4">
              <ToastContainer />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/*" element={<PageNotFound />} />
              </Routes>
            </div>
          </div>
        </>
      )}
    </>
  );
}
