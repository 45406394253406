import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { supabase } from "../lib/supabaseClient";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { useSettings } from "../lib/useSettings";
import { toast } from 'react-toastify'

export default function CategoryForm({setShowDropdownMenu,setShowForm}) {

  const { userSession } = useContext(UserContext)

  const [userGoToPage, setUserGoToPage, userCloseForm, setUserCloseForm] = useSettings();

  const navigation = useNavigate();
  const goTo = () =>
    userGoToPage &&
    newCategory.type.length && newCategory.name.length
      ? navigation("/categories")
      : null;

  const [newCategory, setNewCategory] = useState({
    type: "",
    name: "",
    user_id: userSession.user.id,
  });

  const addCategory = async (newCategory) => {
    try {
      const { data, error } = await supabase
        .from("categories")
        .insert([{ ...newCategory, user_id: userSession.user.id }]);
      if (error) throw error;
    } catch (error) {
      console.log("error@categories:", error);
    }
  };

  const handleCloseForm = () => {
    setShowDropdownMenu(false)
    setShowForm(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.success(`${newCategory.type.toUpperCase()} category ${newCategory.name.toUpperCase()} created!`)
    addCategory(newCategory);
    setNewCategory({
      type: "",
      name: "",
      user_id: userSession.user.id,
    });
    if (userCloseForm) handleCloseForm();
    if (userGoToPage) goTo();
  };

  const completeForm =
    newCategory &&
    newCategory.type &&
    newCategory.name
      ? true
      : false;

  const handleKeys = (event) => {
    if (event.key === "Enter") event.preventDefault();
  };

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-xs">
        <span className="text-center text-sm text-slate-600 font-bold py-2">
          CREATE NEW CATEGORY
        </span>
        <select
          className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200"
          name="type"
          onChange={(event) => {
            setNewCategory({
              ...newCategory,
              [event.target.name]: event.target.value,
            });
          }}
          value={newCategory.type ? newCategory.type : ""}
          autoFocus
        >
          <option value="" disabled>
            select category type
          </option>
          <option value="income">Income</option>
          <option value="expense">Expense</option>
        </select>
        <input
          className="outline-none py-2 focus:ring-offset-2 focus:ring-1 focus:ring-slate-400 focus:bg-slate-200/20 border-b border-slate-200"
          autoComplete="off"
          placeholder="enter name of new account"
          type="text"
          name="name"
          value={newCategory.name ? newCategory.name : ""}
          onChange={(event) => {
            setNewCategory({
              ...newCategory,
              [event.target.name]: event.target.value,
            });
          }}
          onKeyDown={handleKeys}
        />
        <div className="flex items-center">
          <span className="flex-1 text-center">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                onClick={handleCloseForm}
                className="text-slate-600 text-3xl hover:text-slate-400 cursor-pointer"
              />
            </button>
          </span>
          <span className="flex-1 text-center">
            <button
              onClick={handleSubmit}
              disabled={ !completeForm }
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                type="submit"
                className={
                  completeForm
                    ? "text-3xl text-slate-600 cursor-pointer hover:text-slate-400"
                    : "text-3xl text-slate-200"
                }
              />
            </button>
          </span>
        </div>
      </form>
      <div className="flex-1">
        <div className="mt-32">
          <span className="text-[.6rem] text-slate-700">
            Go to page after saving?
          </span>
        </div>
        <div className="flex items-center gap-1">
          <span className="flex-[2.5] text-right text-[0.5rem] font-bold text-slate-600">
            {userGoToPage ? "ON" : "OFF"}
          </span>
          <button
            className="flex flex-[0.5]"
            onClick={() => setUserGoToPage((state) => !state)}
          >
            <FontAwesomeIcon
              className="flex-1 h-6 text-slate-600 hover:text-slate-400"
              icon={userGoToPage ? faToggleOn : faToggleOff}
            />
          </button>
        </div>
      </div>
      <div className="mt-4">
        <span className="text-[.6rem] text-slate-700">
          Return to menu after saving?
        </span>
        <div className="flex items-center gap-1">
          <span className="flex-[2.5] text-right text-[0.5rem] font-bold text-slate-600">
            {userCloseForm ? "ON" : "OFF"}
          </span>
          <button
              className="flex flex-[0.5]"
              onClick={() => setUserCloseForm((state) => !state)}
            >
              <FontAwesomeIcon
                className="flex-1 h-6 text-slate-600 hover:text-slate-400"
                icon={userCloseForm ? faToggleOn : faToggleOff}
              />
      </button>
        </div>
      </div>
    </div>
  );
}
