import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileEdit, faTrashCan, faCheck, faXmark, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { supabase } from "../lib/supabaseClient";

export default function CategoryItem({
  categoryRow,
  removeCategory,
  updateCategory
}) {
  const [enableEditCategory, setEnableEditCategory] = useState(false);
  const [editRow,setEditRow] = useState(categoryRow)

  const editCategory = () => setEnableEditCategory((state) => !state);

  const handleSaveEdit = () => {
    updateColumn(editRow)
    setEnableEditCategory(false);
    setEditRow(categoryRow)
  };

  const handleChange = (event) => {
    setEditRow({ ...categoryRow, [event.target.name]: event.target.value });
    updateCategory(event,editRow)
  };

  const handleCancelEdit = (event) => {
    updateCategory(event,categoryRow)
    setEnableEditCategory(false);
    setEditRow(categoryRow)
  }

  const updateColumn = async (row) => {
    if (editRow == categoryRow) return
    try {
      const { data, error } = await supabase
        .from("categories")
        .update({ 'type': row.type, 'name': row.name })
        .eq("id", row.id);
    } catch (error) {
      console.log("error@categories:", error);
    }
  };

  const renderEditSaveCancel = () => {
    return !enableEditCategory ? (
      <div className="text-center text-[1rem]">
        <FontAwesomeIcon
          className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-[680px]:text-sm"
          icon={faFileEdit}
          onClick={editCategory}
        />
      </div>
    ) : (
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faCheck}
          className="flex-1 text-xl text-slate-400 hover:text-slate-600 hover:cursor-pointer max-[680px]:text-sm"
          onClick={handleSaveEdit}
        />
        <FontAwesomeIcon
          icon={faXmark}
          className="flex-1 text-xl text-slate-400 hover:text-slate-600 hover:cursor-pointer max-[680px]:text-sm"
          onClick={handleCancelEdit}
        />
      </div>
    );
  };

  const textColors = type => type === 'income' ? 'text-green-800 flex-1 ml-2 max-[680px]:text-[.625rem] max-[900px]:hidden' : 'text-rose-800 flex-1 ml-2 max-[680px]:text-[.625rem] max-[900px]:hidden'

  return (
    <form
      className={`grid grid-cols-10 p-3 text-sm even:bg-white odd:bg-slate-100 ${ enableEditCategory ? 'even:bg-slate-300 odd:bg-slate-300' : '' }`}
      onSubmit={(event) => event.preventDefault()}
    >
      <div className="col-span-2 flex items-center">
        <FontAwesomeIcon 
          className={
            categoryRow.type === 'income'
              ? 'flex-0 text-green-500'
              : 'flex-0 text-rose-500'
            }
          icon={ categoryRow.type === 'income' ? faArrowUp : faArrowDown }
        />
        { enableEditCategory ? (
            <select
              className="ml-2"
              name="type"
              onChange={handleChange}
              defaultValue={categoryRow.type}
            >
              <option value="income">Income</option>
              <option value="expense">Expense</option>
            </select>
        ) : <span className={textColors(categoryRow.type)}>{categoryRow.type.toUpperCase()}</span>
        }
      </div>
      <input
        className="col-span-4 max-[680px]:text-[.625rem]"
        autoComplete="off"
        type="text"
        name='name'
        value={categoryRow.name.toUpperCase()}
        onChange={handleChange}
        disabled={!enableEditCategory}
      />
    <div className='col-span-2'/>
     { renderEditSaveCancel() }
      <div className="text-center text-[1rem]">
        <FontAwesomeIcon
          onClick={removeCategory}
          className="text-slate-500 hover:text-slate-300 hover:cursor-pointer max-[680px]:text-sm"
          icon={faTrashCan}
        />
      </div>
    </form>
  );
}
